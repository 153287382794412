import { useEffect, useState } from 'react'
import { QRCodeSVG } from 'qrcode.react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import {
    AppBar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItemButton,
    ListItemText,
    Stack,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import PersonIcon from '@mui/icons-material/Person';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import descargarXmls from '../functions/descargarXmls';


export default function Index() {

    const [data, setData] = useState(null);
    const [open, setOpen] = useState(false);

    const [bloque, setBloque] = useState(0);
    const [datos, setDatos] = useState([])


    const [params, _] = useSearchParams();
    const params_pkUsuario = params.get('pk')

    const [user, setUser] = useState("")
    const goTo = useNavigate();


    const [dialogUsuario, setDialogUsuario] = useState(false);
    const [dialogConfirmaDescargar, setDialogConfirmaDescargar] = useState(false);




    const cerrarDialog = () => {
        setData(null)
        setOpen(false)
    }







    //* Funciones de descarga


    const descargarTodo = async () => {
        if (params_pkUsuario != null) {
            try {
                let data = await descargarXmls(params_pkUsuario);
                setDatos(data)
                localStorage.setItem("datos", JSON.stringify(data))
            }
            catch (e) {
                console.error("error en descarga =>", e)
            }
        }
    }

    /* 
    async function descargarBloque(bloque) {
        let data = await descargarXmls(params_pkUsuario, bloque);
        return data;
    }
 */



    useEffect(() => {
        descargarTodo()

        if (params_pkUsuario != null) {
            localStorage.setItem("pkUsuario", JSON.stringify(params_pkUsuario))
        } else {
            const pkuserls = localStorage.getItem("pkUsuario")
            if (pkuserls != null) {
                goTo(`/?pk=${JSON.parse(pkuserls)}`)
            }
        }

        const interval = setInterval(descargarTodo, 30000)

        return () => clearInterval(interval);
    }, [params_pkUsuario]);










    const eliminarRegistrosAntiguos = () => {
        let oDatos = [...datos];
        function sumarDias(fecha, dias) {
            fecha.setDate(fecha.getDate() + dias);
            return fecha;
        }
        let d = new Date();
        const fechaHaceDosDias = sumarDias(d, -2);

        const filtrados = oDatos.filter(oDato => {
            const fechaODato = new Date(oDato.createdAt)
            return (fechaODato >= fechaHaceDosDias)
        })
        setDatos(filtrados)
        localStorage.setItem("datos", JSON.stringify(filtrados))
    }







    return (
        <>
            <AppBar position="sticky" sx={{ background: '#acd' }} >
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'black' }}>
                        {params_pkUsuario != null &&
                            <>
                                <b>Usuario: </b>{params_pkUsuario}
                            </>
                        }
                    </Typography>

                    {
                        params_pkUsuario != null &&
                        <Tooltip title='Seleccionar ID de usuario' >
                            <IconButton color="inherit" onClick={() => setDialogUsuario(true)} >
                                <PersonIcon sx={{ color: 'black' }} />
                            </IconButton>
                        </Tooltip>
                    }

                    <Tooltip title='Eliminar registros anteriores a dos días' >
                        <IconButton color="inherit" onClick={eliminarRegistrosAntiguos} >
                            <EventBusyIcon sx={{ color: 'black' }} />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title='Volver a descargar toda la lista' >
                        <IconButton color="inherit" onClick={() => setDialogConfirmaDescargar(true)} >
                            <FileDownloadIcon sx={{ color: 'black' }} />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>

            {
                params_pkUsuario === null
                    ? <Box display="flex" justifyContent="center" mt={5} >
                        <Stack spacing={1} justifyContent="center" >
                            <Typography textAlign="center" >Número de usuario:</Typography>
                            <TextField
                                size="small"
                                value={user}
                                onChange={e => setUser(e.target.value)}
                                variant="outlined"
                            />
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => {
                                    setUser("")
                                    goTo(`/?pk=${user}`)
                                }}
                            >
                                Consultar
                            </Button>
                        </Stack>
                    </Box>




                    : <List>
                        {
                            datos.map(reg => {
                                return (
                                    <ListItemButton
                                        key={reg.pkRegistro}
                                        onClick={() => {
                                            setData(reg)
                                            setOpen(true)
                                        }}
                                        divider
                                    >
                                        <ListItemText
                                            primary={<strong> Registro: {reg.pkRegistro}</strong>}
                                            secondary={<>{reg.envio} - No. identificación: {reg.conceptos.noIdentificacion} </>}
                                        />
                                    </ListItemButton>
                                )
                            })
                        }

                        {data &&
                            <Dialog
                                open={open}
                                fullScreen
                                onClose={cerrarDialog}
                            >
                                <DialogTitle sx={{ background: '#acd', display: 'flex', alignItems: 'center' }} >
                                    <IconButton sx={{ mr: 1 }} onClick={cerrarDialog} >
                                        <KeyboardBackspaceIcon />
                                    </IconButton>
                                    {"Datos"}
                                </DialogTitle>
                                <Box sx={{ maxHeight: "calc(100vh - 80px)", overflow: 'auto' }} >

                                    <Box sx={{ m: '0 auto', p: 2, py: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <QRCodeSVG value={`https://verificacfdi.facturaelectronica.sat.gob.mx/default.aspx?&id=${data.cfdi.uuid}&re=${data.emisor.rfc}&rr=${data.receptor.rfc}&tt=${data.cfdi.total}&fe=${data.cfdi.sello.substr(-8)}`}
                                            size={300}
                                        />
                                    </Box>


                                    <Titulo texto='EMISOR / RECEPTOR / ENCABEZADO' />

                                    <Box sx={{ p: 2 }} >

                                        <Subtitulo>Emisor:</Subtitulo>

                                        <Dato b='Nombre'>{data.emisor.nombre}</Dato>
                                        <Dato b='RFC'>{data.emisor.rfc}</Dato>
                                        <Dato b='Régimen fiscal'>{data.emisor.regimen}</Dato>
                                        <Dato b='Lugar de expedición'>{data.emisor.lugarExpedicion}</Dato>

                                        <Divider sx={{ my: 2 }} />

                                        <Subtitulo>Receptor</Subtitulo>

                                        <Dato b='Nombre'>{data.receptor.nombre}</Dato>
                                        <Dato b='RFC'>{data.receptor.rfc}</Dato>
                                        <Dato b='Uso de CFDI'>{data.receptor.usoCfdi}</Dato>
                                        <Dato b='CFDI relacionados'>{data.receptor.cfdiRelacionados}</Dato>
                                        <Dato b='Tipo de relación'>{data.receptor.tipoDeRelacion}</Dato>

                                    </Box>
                                    <br />

                                    <Titulo texto='CONCEPTOS' />
                                    <Box sx={{ p: 2 }} >
                                        <Subtabla titulo='Descripción'>{data.conceptos.descripcion}</Subtabla>
                                        <Subtabla titulo='Cantidad'>{data.conceptos.cantidad}</Subtabla>
                                        <Subtabla titulo='Clave producto/servicio'>{data.conceptos.claveProdServ}</Subtabla>
                                        <Subtabla titulo='Clave unidad'>{data.conceptos.claveUnidad}</Subtabla>
                                        <Subtabla titulo='Número de identificación'>{data.conceptos.noIdentificacion}</Subtabla>
                                        <Subtabla titulo='Importe'>{data.conceptos.importe}</Subtabla>
                                    </Box>
                                </Box>
                            </Dialog>
                        }
                    </List >
            }






            {
                //* Dialog para seleccionar usuario
            }
            <Dialog open={dialogUsuario} onClose={() => setDialogUsuario(false)} >
                <DialogTitle>
                    Introducir ID de usuario
                </DialogTitle>
                <DialogContent>
                    <TextField
                        size="small"
                        autoFocus
                        value={user}
                        onChange={e => setUser(e.target.value)}
                        variant="outlined"
                    />
                </DialogContent>
                <form onSubmit={e => {
                    e.preventDefault()
                    setUser("")
                    goTo(`/?pk=${user}`)
                    setDialogUsuario(false)
                }} >
                    <DialogActions>
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<CheckIcon />}
                            type="submit"
                        >
                            Aceptar
                        </Button>

                        <Button
                            onClick={() => {
                                setUser("")
                                setDialogUsuario(false)
                            }}
                            variant="outlined"
                            size="small"
                            startIcon={<CancelIcon />}
                        >
                            Cancelar
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>





            {
                //* Dialog para confirmar descargar todo
            }
            <Dialog open={dialogConfirmaDescargar} onClose={() => setDialogConfirmaDescargar(false)} >
                <DialogTitle>
                    Descargar lista
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Seguro que desea volver a descargar la lista?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            descargarTodo()
                            setDialogConfirmaDescargar(false)
                        }}
                        variant="contained"
                        size="small"
                        startIcon={<CheckIcon />}
                    >
                        Aceptar
                    </Button>
                    <Button
                        onClick={() => setDialogConfirmaDescargar(false)}
                        variant="outlined"
                        size="small"
                        startIcon={<CancelIcon />}
                    >
                        Regresar
                    </Button>
                </DialogActions>
            </Dialog>







        </>
    )
}


function Titulo({ texto }) {
    return (
        <Box sx={{ background: '#cef', p: 1 }} >
            <Typography sx={{ textAlign: 'center' }} >
                {texto}
            </Typography>
        </Box>
    )
}

function Subtitulo({ children }) {
    return (
        <Typography variant='h5' sx={{ textAlign: 'center', mb: 1 }} >
            <strong> {children} </strong>
        </Typography>
    )
}

function Dato({ children, b }) {
    return (
        <Box sx={{ mb: 2 }} >
            <Typography><strong>{b}: </strong></Typography>
            <Typography>{children}</Typography>
        </Box>
    )
}

function Subtabla({ titulo, children }) {
    return (
        <Box sx={{ background: "#eee", mb: 1 }} >
            <Box sx={{ width: '100%', background: '#ddd' }} >
                <Typography sx={{ color: 'darkcyan', textAlign: 'center' }} >
                    {titulo}
                </Typography>
            </Box>
            <Box sx={{ p: 1 }} >
                <Typography sx={{ textAlign: 'center' }} >
                    {children}
                </Typography>
            </Box>
        </Box>
    )
}