import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Index from './components/Index';
import FileReadModule from './components/fileRead_module';


export default function App() {

    return (

        <BrowserRouter>
            
                <Routes>
                    <Route index element={<Index />} />
                    <Route path="/xml-module" element={<FileReadModule />} />
                </Routes>
            
        </BrowserRouter>

    )
}
