import axios from 'axios';
const convert = require('xml-js')

export default async function descargarXmls(pk, bloque = null) {
    const url = `https://www.samicasab.com/p2/api/Registros/${pk}${bloque === null
        ? ""
        : `/${bloque}`}`


    
    const snap = await axios({
        method: "get",
        url
    }).then(res => {
        
        return res.data.data
    });

    let lista = []
    snap.forEach(async (doc) => {

        let data = null;
        const config = { compact: true, spaces: 4 }
        if (doc.valorRegistro.charAt(-1) === "") {
            data = await convert.xml2js(doc.valorRegistro.slice(0, -1), config)
        } else if (doc.valorRegistro.charAt(-1) === ">") {
            data = await convert.xml2js(doc.valorRegistro, config)
        }

        if (data != null) {
            lista.push({
                pkRegistro: doc.pkRegistro,
                pkUsuario: doc.pkUsuario,
                bloque: doc.bloque,
                createdAt: doc.createdAt,
                cfdi: {
                    uuid: data["cfdi:Comprobante"]["cfdi:Complemento"]["tfd:TimbreFiscalDigital"]["_attributes"].UUID,
                    folio: data["cfdi:Comprobante"]["_attributes"].Folio,
                    noCertificado: data["cfdi:Comprobante"]["_attributes"].NoCertificado,
                    total: data["cfdi:Comprobante"]["_attributes"].Total,
                    lugarExpedicion: data["cfdi:Comprobante"]["_attributes"].LugarExpedicion,
                    certificado: data["cfdi:Comprobante"]["_attributes"].Certificado,
                    sello: data["cfdi:Comprobante"]["_attributes"].Sello
                },
                emisor: {
                    nombre: data["cfdi:Comprobante"]["cfdi:Emisor"]["_attributes"].Nombre,
                    rfc: data["cfdi:Comprobante"]["cfdi:Emisor"]["_attributes"].Rfc,
                    lugarExpedicion: data["cfdi:Comprobante"]["_attributes"].LugarExpedicion,
                    regimen: data["cfdi:Comprobante"]["cfdi:Emisor"]["_attributes"].RegimenFiscal
                },
                receptor: {
                    nombre: data["cfdi:Comprobante"]["cfdi:Receptor"]["_attributes"].Nombre,
                    rfc: data["cfdi:Comprobante"]["cfdi:Receptor"]["_attributes"].Rfc,
                    usoCfdi: data["cfdi:Comprobante"]["cfdi:Receptor"]["_attributes"].UsoCFDI,
                    cfdiRelacionados: data["cfdi:Comprobante"]["cfdi:CfdiRelacionados"]["cfdi:CfdiRelacionado"]["_attributes"].UUID,
                    tipoDeRelacion: data["cfdi:Comprobante"]["cfdi:CfdiRelacionados"]["_attributes"].tipoDeRelacion
                },
                conceptos: {
                    descripcion: data["cfdi:Comprobante"]["cfdi:Conceptos"]["cfdi:Concepto"]["_attributes"].Descripcion,
                    cantidad: data["cfdi:Comprobante"]["cfdi:Conceptos"]["cfdi:Concepto"]["_attributes"].Cantidad,
                    claveProdServ: data["cfdi:Comprobante"]["cfdi:Conceptos"]["cfdi:Concepto"]["_attributes"].ClaveProdServ,
                    claveUnidad: data["cfdi:Comprobante"]["cfdi:Conceptos"]["cfdi:Concepto"]["_attributes"].ClaveUnidad,
                    noIdentificacion: data["cfdi:Comprobante"]["cfdi:Conceptos"]["cfdi:Concepto"]["_attributes"].NoIdentificacion,
                    importe: data["cfdi:Comprobante"]["cfdi:Conceptos"]["cfdi:Concepto"]["_attributes"].Importe
                },
                envio: (data["cfdi:Comprobante"]["cfdi:Complemento"]["cartaporte20:CartaPorte"] === undefined
                    ? 'Local'
                    : data["cfdi:Comprobante"]["cfdi:Complemento"]["cartaporte20:CartaPorte"]["cartaporte20:Ubicaciones"]["cartaporte20:Ubicacion"][1]["_attributes"].NombreRemitenteDestinatario
                )
            })
        }
    })


    const nl = lista
        .sort((a, b) => a.pkRegistro - b.pkRegistro);

    return nl;
}   