import { useState } from 'react'
import { useNavigate } from 'react-router-dom'


const convert = require('xml-js')


export default function FileReadModule() {
    const [texto, setTexto] = useState('')
    const [archivo, setArchivo] = useState('')

    const navigate = useNavigate();

    const leerArchivo = (e) => {
        const file = e.target.files[0];
        if (!file) { return }
        const fileReader = new FileReader();
        fileReader.readAsText(file)
        fileReader.onload = () => {
            convertirAJson(fileReader.result)
        }
        fileReader.onerror = () => {
            console.log(fileReader.error)
        }
        setArchivo('')
    }

    function convertirAJson(data) {
        const config = { compact: true, spaces: 4 }
        const result = convert.xml2json(data, config);
        setTexto(JSON.stringify(JSON.parse(result), null, "\t"))
        let array = []
        array.push(JSON.parse(result))
        localStorage.setItem('xml', JSON.stringify(array))
        navigate('/', { replace: true })
    }

    return (
        <div>
            <input type="file" value={archivo} onChange={leerArchivo} />

            <div>
                <h3>Este es el json generado:</h3>
                <pre>
                    {texto}
                </pre>
            </div>

        </div>
    )
}